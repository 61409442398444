import React, {useState} from 'react';
import arrow from "../../images/next.svg";
import qr from '../../images/qr.svg';
import tv from '../../images/tv.svg';

const Barra = ({touched, steps, setSteps, data1, selected1 = 0, data2, selected2 = 1, data3, selected3 = 0}) => {

    let selFascia = selected3;
    if (selected3 == null || selected3 == undefined) {
        selFascia = selected1;
    }

    let data1Response = (
        <span className="data1Response dataResponse">
            <div style={{width:12,height:12,background:data1?.colorBlocks[selected1]?.hexColor, borderRadius:15, display:'inline-block', marginRight:5}}>
            </div>
            {data1?.colorBlocks[selected1].colorTitle}
        </span>
    )
    
    let data2Response = (
        <span className="data2Response dataResponse">
            <div style={{padding:'2px 5px', borderRadius:4, display:'inline-block', marginRight:5,marginTop:-5, border:'1px solid #091153'}}>
                {data2?.sizes?.sizes[selected2].size}
            </div>
            {data2?.sizes?.sizes[selected2].inches}''
        </span>
    )

    let data3Response = (
        <span className="data3Response dataResponse">
            <div style={{width:12,height:12,background:data3?.colorBlocks[selFascia || 0].hexColor, borderRadius:15, display:'inline-block', marginRight:5}}>
            </div>
            {data1?.colorBlocks[selFascia || 0].colorTitle}
        </span>
    )

    let data4Response = (
        <span className="data4Response dataResponse">
            <div style={{display:'flex', width:'100%', maxWidth:'100%', justifyContent:'flex-start', alignItems:'center'}}>
                <img style={{width:22,height:22, marginRight:8, marginTop:-5}} src={tv}/>
            </div>
        </span>
    )

    return (
        <div className="Barra">
            <div className="actualBarra"></div>
            <div className="comands">
                <ul>
                    <li className={!steps ? 'active' : ''}>
                        <p>
                            1. Choose the size
                        </p>
                        {
                            touched[0] && data2Response
                        }
                    </li>
                    <li className={steps === 1 ? 'active' : ''}>
                        <p>
                            2. Choose the colour 
                        </p>
                        {
                            touched[1] && data1Response
                        }
                    </li>
                    <li className={steps === 2 ? 'active' : ''}>
                        <p>
                            3. Pick the speaker 
                        </p>
                        {
                            touched[2] && data3Response
                        }
                    </li>
                    <li className={steps === 3 ? 'active' : ''}>
                        <p>
                            4. Get your TV
                        </p>
                        {
                            steps >= 3 && data4Response
                        }
                    </li>
                </ul>
                <div className="prevNextBtn">
                    {!steps ? (
                        <div style={{opacity : 0}}>
                            <h6 style={{opacity:0}}>Back</h6>
                            <img className="rev" src={arrow} />
                        </div>
                    ) : (
                        <div onClick={() => setSteps(steps - 1)}>
                            <h6>Back</h6>
                            <img className="rev" src={arrow} />
                        </div>
                    )}
                    {steps >= 3 ? (
                        <div style={{opacity : 0}}>
                            <h6 style={{opacity:0}}>Next</h6>
                            <img src={arrow} />
                        </div>
                    ) : (
                        <div onClick={() => setSteps(steps + 1)}>
                            <h6>Next</h6>
                            <img src={arrow} />
                        </div>
                    )}

                    
                </div>
            </div>
        </div>
    )
}

export default Barra;