import React, {useEffect, useState} from 'react';
import QRCode from "react-qr-code";
import { navigate } from "gatsby";
import sendDataToGA from '../../../utils/ga';
import {GATSBY_FE_URL} from '../../../config/var';



const Step = ({data1, data2, data3, data4, selectedColor = 0, selectedSize = 1, storeID, selectedFascia}) => {

    const [active, setActive] = useState(0)

    useEffect(() => {
        typeof window !== 'undefined' && navigate(`?storeID=${storeID}&colorCode=${data1.colorBlocks[selectedColor].colorCode}&sizeCode=${data2.sizes.sizes[selectedSize].sizeCode}&fasciaCode=${data3.colorBlocks[selectedFascia].colorCode}`);
        setTimeout(() => {
            if (typeof window !== "undefined") {
                if (window.ga) {
                    sendDataToGA();
                }
            }
        }, 1000);
    

    }, [selectedSize, selectedColor, selectedFascia])

    useEffect(() => {
        setActive(1);
    }, [])
    
    
    const sID = typeof window !== 'undefined' ? localStorage.getItem('SKYStoreID') : undefined; 
    
    const image = data4?.mainImagesWithSize[selectedColor].colorCluster.filter((el) => {
        //console.log(el.sizecode, data2.sizes.sizes[selectedSize].sizeCode);
        return el.sizecode === data2.sizes.sizes[selectedSize].sizeCode
    });

    //console.log(image);

    let sizeFascia;
    if (!selectedSize) {
        sizeFascia = 's';
    } else if (selectedSize === 1) {
        sizeFascia = 'm';
    } else {
        sizeFascia = 'l';
    }

    const fascia = data3.colorBlocks[selectedFascia]?.listOfImagesForColours[sizeFascia];
    
    
    let id = data4.products.filter((el) => {
        return el.colorCode === data1.colorBlocks[selectedColor].colorCode && el.sizeCode === data2.sizes.sizes[selectedSize].sizeCode
    })

    return (
        <div className="Step3 step">
            <div className="title">
               {<div dangerouslySetInnerHTML={{__html: data4?.mainText}}></div>} 
            </div>
            <div className="main">
                <div className="img">
                    <div id="imgCont" className={active ? 'active' : ''}>
                        <img src={image[0].image?.mediaItemUrl} />
                        {selectedColor !== selectedFascia && <img className="overImage" src={fascia?.mediaItemUrl} />}
                    </div>
                </div>
                <div className="qr">
                    {/* <a target="_blank" href={`${window.location.origin}/products?storeID=${sID}&colorCode=${data1.colorBlocks[selectedColor].colorCode}&sizeCode=${data2.sizes.sizes[selectedSize].sizeCode}&fasciaCode=${data3.colorBlocks[selectedFascia].colorCode}&QR=true`}> */}
                        {/* TODO done - use 4 testing */}
                        {/* <QRCode size={140} value={`https://staging.skyglassretail.com/products?storeID=${sID}&colorCode=${data1.colorBlocks[selectedColor].colorCode}&sizeCode=${data2.sizes.sizes[selectedSize].sizeCode}&fasciaCode=${data3.colorBlocks[selectedFascia].colorCode}&QR=true`} /> */}
                        <QRCode size={140} value={`${process.env.GATSBY_FE_URL}/products?storeID=${sID}&colorCode=${data1.colorBlocks[selectedColor].colorCode}&sizeCode=${data2.sizes.sizes[selectedSize].sizeCode}&fasciaCode=${data3.colorBlocks[selectedFascia].colorCode}&QR=true`} />
                    {/* </a> */}
                    <div className="instructions">
                        <ul>
                            {data4?.instructions.map((itm, index) => {
                                return <li key={index}>
                                    <h3>{index + 1}.</h3>
                                    <p dangerouslySetInnerHTML={{__html: itm?.text}}></p>
                                </li>
                            })}
                        </ul>
                    </div>
                </div>
            </div>
         </div>

        // <div className="Step3">
        //     <p>COLOR: {data1.colorBlocks[selectedColor].colorCode}</p>
        //     <p>SIZE: {data2.sizes.sizes[selectedSize].sizeCode}</p>
        //     <p>ID: {id[0].productPage.databaseId}</p>
        //     <QRCode value={`${window.location.origin}/products?productID=${id[0].productPage.databaseId}&storeID=${sID}&colorCode=${data1.colorBlocks[selectedColor].colorCode}&sizeCode=${data2.sizes.sizes[selectedSize].sizeCode}`} />
        // </div>
    )
}

export default Step;