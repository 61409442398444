import React, { useState, useRef, useEffect } from 'react';
import close from '../../images/close.svg';
import Flickity from "react-flickity-component";
import "flickity/dist/flickity.min.css";

const ModalCarousel = ({hideModal, setModal, color, images, chosenImage, changeClass}) => {

    const [active, setActive] = useState(0)

    const flRef = useRef();

    // useEffect(() => {
    //     setActive(chosenImage)
    // }, [chosenImage])

    const closeItPlease = () => {
        hideModal()
    }
    
    return (
        <div className={changeClass ? "active Modal" : "Modal"}>
            <div onClick={() => closeItPlease()} className="close">
                <div style={{textAlign:'right'}}>
                    <img src={close} style={{width:20, margin:30}}/>
                </div>
            </div>
            <div>
                <Flickity
                    flickityRef={() => {}}
                    ref={flRef}
                    static
                    className={"main-carousel"}
                    elementType={"div"}
                    options={{
                    contain: false,
                    prevNextButtons: true,
                    autoPlay: false, 
                    prevNextButtons: true,
                    contain: true,
                    wrapAround:true,
                    initialIndex:chosenImage,
                    arrowShape : "M69.5719282,7.87947368 C69.7298915,8.07090526 69.8725821,8.24023158 70,8.39452632 L31.4111422,45.9734737 L70,83.5789474 C69.8725821,83.7292632 69.7298915,83.9251158 69.5719282,84.1169895 C68.7812391,85.0454105 66.4475717,88 64.6999393,88 C63.9363047,87.9659579 63.2922334,87.6693053 62.6595076,87.0640632 L23.0561067,48.4810947 C21.6479644,47.1088 21.6479644,44.8836842 23.0561067,43.5153684 L62.6595076,4.9324 C63.2922334,4.32317895 63.9363047,4.02652632 64.6999393,4 C66.4475717,4 68.7812391,6.94707368 69.5719282,7.87947368 Z",
                    // pauseAutoPlayOnHover:false,
                    on: {
                        ready: function() {
                            setActive(chosenImage);
                        },
                        change: function( index ) {
                            // flRef && flRef.current && flRef.current.flkty.player.play()
                            setTimeout(() => {
                                console.log(index)
                                setActive(index);
                            }, 1);
                        },
                    }
                }}
                >
                    {images[color].gallery.map((item, i) => (
                    <div className="carousel-cell">
                        <img
                            src={item?.mediaItemUrl}
                            key={i}
                        />
                    </div>
                    ))}
                </Flickity>
                <div className="counterCont">
                    <div className="counter" style={{width:(images[color].gallery.length ) * 30}}>
                        {images[color].gallery.map((item, i) => {
                            return (
                                <div key={i} className={i === active ? 'active' : ''}>
                                    <div className="txt" style={{width:30}}>
                                        <div>
                                            <div className="bat">
                                            </div>
                                            <h6 style={{color:'#666', paddingTop:7}}>{i + 1}</h6>
                                        </div>
                                    </div>
                                </div>
                                )
                            }        
                            )} 
                        </div>
                    </div>
                </div>
            
        </div>
    )
}

export default ModalCarousel;