import React, {useEffect} from 'react';
import Gallery from '../editorial/Gallery';

const Step = ({touched, setTouched, data, selected = 0, setSelected, setSelectedSize}) => {
    
    useEffect(() => {
        let oldTouch = [...touched];
        oldTouch[1] = true;
        setTouched(oldTouch)
    }, [])

    return (
        <div className="Step1 step">
            <div className="title">
               {<div dangerouslySetInnerHTML={{__html: data?.mainText}}></div>} 
            </div>
            <div className="main">
                <div className="galleryCont">   
                    <Gallery withH4 withClick selected={selected} setSelected={setSelected} invert info={data} />
                </div>
            </div>
        </div>
    )
}

export default Step;