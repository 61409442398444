import logo from '../../../src/images-static/tv/logo-glass.jpg';

const tv = {
    data : {
        wp: {
            tv: {
                dataHeader : {
                    home:true, 
                    back:true,
                    logo:logo,
                    buttons : [
                        {
                            links : '/tv/reviews',
                            label : 'Reviews'
                        },
                        {
                            links : '/tv/glass/tech-specs',
                            label : 'Tech Talk'
                        },
                        {
                            links : '/tv/glass/configurator',
                            label : 'Choose your tv'
                        },
                    ]
                },
            }
        },

    }
}

export default tv;