import React, {useEffect} from 'react';
import GallerySingle from '../editorial/GallerySingle';

const Step = ({touched, setTouched, data3, selectedFascia, setSelectedFascia, selectedColor}) => {

    useEffect(() => {
        if (selectedFascia == null || selectedFascia == undefined) {
            setSelectedFascia(selectedColor || 0);
        }
        let oldTouch = [...touched];
        oldTouch[2] = true;
        setTouched(oldTouch)
    }, [])
    return (
        <div className="Step1 step">
            <div className="title">
               {<div dangerouslySetInnerHTML={{__html: data3?.mainText}}></div>} 
            </div>
            <div className="main">
                <div className="galleryCont">   
                    <GallerySingle
                        selectedFascia={selectedFascia} 
                        selectedColor={selectedColor} 
                        setSelectedFascia={setSelectedFascia} 
                        invert 
                        info={data3} 
                    />
                </div>
            </div>
        </div>
    )
}

export default Step;