import React, {useState} from 'react';
import StepSize from './StepSize'
import StepColor from './StepColor'
import StepQR from './StepQR'
import StepFascia from './StepFascia'

const StepsContainer = ({
    steps, 
    data1, 
    data2, 
    data3, 
    data4, 
    touched, 
    setTouched,
    selectedColor, 
    setSelectedColor, 
    selectedFascia, 
    setSelectedFascia, 
    selectedSize, 
    setSelectedSize, 
    storeID}) => {

    return (
        <div className="StepsContainer">
            {!steps &&      <StepSize
                                touched={touched}
                                setTouched={setTouched}
                                storeID={storeID} 
                                data={data2} 
                                selectedColor={selectedColor} 
                                selected={selectedSize} 
                                setSelected={setSelectedSize}
                                />}

            {steps === 1 && <StepColor 
                                touched={touched}
                                setTouched={setTouched}
                                storeID={storeID} 
                                data={data1} 
                                selected={selectedColor} 
                                setSelected={setSelectedColor} 
                                setSelectedSize={setSelectedSize}
                                />}

            {steps === 2 && <StepFascia 
                                touched={touched}
                                setTouched={setTouched}
                                storeID={storeID} 
                                data3={data3} 
                                selectedFascia={selectedFascia} 
                                setSelectedFascia={setSelectedFascia} 
                                selectedColor={selectedColor} 
                                selectedSize={selectedSize}
                                />}

            {steps === 3 && <StepQR 
                                touched={touched}
                                setTouched={setTouched}
                                storeID={storeID} 
                                data1={data1} 
                                data2={data2} 
                                data3={data3} 
                                data4={data4} 
                                selectedFascia={selectedFascia} 
                                selectedColor={selectedColor} 
                                selectedSize={selectedSize} 
                            />}
        </div>
    )
}

export default StepsContainer;