import React, {useState} from 'react';
import close from '../../images/close.svg';
import ModalCarousel from '../configurator/ModalCarousel'; 
import { Link } from "gatsby";

const Gallery = ({info, invert, selected, setSelected, withClick, withButton, storeID, withH4}) => {

    const [modal, setModal] = useState(false);
    const [changeClass, setChangeClass] = useState(false);
    const [chosenImage, setChosenImage] = useState(0);

    const showModal = (index) => {
        setChosenImage(index)
        setModal(true)
        setTimeout(() => {
            setChangeClass(true)
        }, 10);
    }

    const hideModal = () => {
        setChangeClass(false)
        setTimeout(() => {
            setModal(false)
        }, 300);
    }

    let Modal = '';
    if (withClick) {
        Modal = <ModalCarousel hideModal={hideModal} changeClass={changeClass} setChosenImage={setChosenImage} setModal={setModal} color={selected} images={info.colorBlocks} chosenImage={chosenImage}/>
    }
    

    return (
        <>
        <div className={invert ? 'invert Gallery' : 'Gallery'}>
            <div className="controller">
                <ul>
                    {info.colorBlocks.length > 0 && info.colorBlocks.map((itm, index) => {
                        let active = '';
                        if (index === selected) {
                            active = 'active';
                        } 
                        return <li 
                            onClick={() => setSelected(index)} 
                            key={itm.colorTitle} 
                            className={active}>
                                <div style={{background:itm.hexColor, width: 30, height:30, borderRadius: 15}}>

                                </div>
                            </li>
                    })}
                </ul>
                <div>
                    {info.colorBlocks.length > 0 && info.colorBlocks.map((itm, index) => {
                        let active = '';
                        if (index === selected) {
                            return <p key={itm.colorTitle}>{itm.colorTitle}</p>
                        } 
                    })}
                </div>
            </div>
            {withH4 && <h4>Tap on the pictures to zoom in</h4>}
            <div className="gallery">
                <div className="galleryCont">
                    {info.colorBlocks.length > 0 && info.colorBlocks.map((itm, index) => {
                        let active = '';
                        if (index === selected) {
                            active = 'active';
                        } 
                        return (
                            <div className={active} key={"img-" + index}>
                                <div className="big">
                                    <img 
                                        onClick={withClick ? () => showModal(0) : () => true} 
                                        key={itm.gallery[0].slug} 
                                        src={itm.gallery[0].mediaItemUrl} 
                                    />
                                </div>
                                <div className="small">
                                    <img 
                                        key={itm.gallery[1].slug} 
                                        src={itm.gallery[1].mediaItemUrl} 
                                        onClick={withClick ? () => showModal(1) : () => true}
                                    />
                                    <img 
                                        key={itm.gallery[2].slug} 
                                        src={itm.gallery[2].mediaItemUrl} 
                                        onClick={withClick ? () => showModal(2) : () => true}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {withButton && <button><Link state={{choosenColor : selected}} to={`/tv/glass/configurator/?storeID=${storeID}`}>Choose your TV</Link></button>}
        </div>
        {modal && Modal}
        </>
    )
}

export default Gallery;