import React from 'react';
import { Link } from "gatsby";

const Gallery = ({info, invert, selectedFascia, selectedColor , setSelectedFascia, withButton, storeID}) => {

    //console.log('-----', selectedFascia);

    const selected = selectedFascia;

    return (
        <>
        <div className={invert ? 'invert Gallery' : 'Gallery'}>
            <div className="controller">
                <ul>
                    {info.colorBlocks.length > 0 && info.colorBlocks.map((itm, index) => {
                        let active = '';
                        if (index === selected) {
                            active = 'active';
                        } 
                        return <li 
                            onClick={() => setSelectedFascia(index)} 
                            key={itm.colorTitle} 
                            className={active}>
                                <div style={{background:itm.hexColor, width: 30, height:30, borderRadius: 15}}>

                                </div>
                            </li>
                    })}
                </ul>
                <div>
                    {info.colorBlocks.length > 0 && info.colorBlocks.map((itm, index) => {
                        let active = '';
                        if (index === selected) {
                            return <p key={itm.colorTitle}>{itm.colorTitle}</p>
                        } 
                    })}
                </div>
            </div>
            <h4>Tap on the buttons to choose the colour of the fascia</h4>
            <div className="gallery">
                <div className="galleryCont">
                    {info.colorBlocks.length > 0 && info.colorBlocks.map((itm, index) => {
                        let active = '';
                        if (index === selected) {
                            active = 'active';
                        } 
                        return (
                            <div className={active} key={"img-" + index}>
                                <img 
                                    key={itm.gallery.slug} 
                                    src={itm.gallery.mediaItemUrl} 
                                    style={{
                                        display: 'block',
                                        margin: '0 auto',
                                        // height: '320px',
                                        height: '280px',
                                        width: 'auto',
                                        paddingLeft: '1rem'
                                    }}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
            {withButton && <button><Link state={{choosenColor : selected}} to={`/tv/glass/configurator/?storeID=${storeID}`}>Choose your TV</Link></button>}
        </div>
        </>
    )
}

export default Gallery;