import React, {useEffect} from 'react';
import S_off from '../../images/sizes/size-43-off.jpg';
import S_on from '../../images/sizes/size-43-on.jpg';
import M_off from '../../images/sizes/size-55-off.jpg';
import M_on from '../../images/sizes/size-55-on.jpg';
import L_off from '../../images/sizes/size-65-off.jpg';
import L_on from '../../images/sizes/size-65-on.jpg';

const Step = ({touched, setTouched, data, selected, setSelected, selectedColor= 0}) => {

    useEffect(() => {
        let oldTouch = [...touched];
        oldTouch[0] = true;
        setTouched(oldTouch)
    }, [])
    
    return (
        <div className="Step2 step">
            <div className="title">
               {<div dangerouslySetInnerHTML={{__html: data?.mainText}}></div>} 
            </div>
            <div className="main">
                <div className="images">
                    {data?.sizes?.sizes.length > 0 && 
                            data?.sizes?.sizes.map((itm, index) => {
                                if (index === selected) {
                                    return <div key={`${itm}-${index}`} className="active">
                                        <img src={itm.images[selectedColor].image.mediaItemUrl} />
                                    </div>
                                } else {
                                    return <div key={`${itm}-${index}`}>
                                        <img src={itm.images[selectedColor]?.image.mediaItemUrl} />
                                    </div>
                                }
                        })}
                </div>
                <div className="choose">
                    <h4>Tap on the icons to choose the size</h4>
                    { !selected ?       <img src={S_on}/> : <img onClick={() => setSelected(0)} src={S_off}/> }
                    { selected === 1 ?  <img src={M_on}/> : <img onClick={() => setSelected(1)} src={M_off}/> }
                    { selected === 2 ?  <img src={L_on}/> : <img onClick={() => setSelected(2)} src={L_off}/> }
                </div>
            </div>
        </div>
    )
}

export default Step;