import React, {useState, useEffect} from 'react';
import Barra from '../../../components/configurator/Barra';
import StepsContainer from '../../../components/configurator/StepsContainer';
import Layout from '../../../UI/Layout';
import {graphql} from 'gatsby';
import useLS from '../../../hooks/useLS';
import HeaderData from '../../../../queries/data/header/header-glass';

const Configurator = (props) => {

    const {data, location, uri} = props;

    // STEPS
    const [steps, setSteps] = useState(0);
    // COLOR
    const [selectedColor, setSelectedColor] = useState(0);
    // SIZE
    const [selectedSize, setSelectedSize] = useState(1);
    // FASCIA
    const [selectedFascia, setSelectedFascia] = useState(null);
    //TOUCH
    const [touched, setTouched] = useState([false, false, false, false])

    const LSData = useLS();

    const dataHeader = HeaderData?.data?.wp?.tv?.dataHeader;


    useEffect(() => {
      location?.state && setSelectedColor(location.state.choosenColor);
    }, [])

    const homeData = data.wp.pages.nodes.filter((itm) => itm.databaseId === 2);

    const configData1 = homeData[0].sky.choooseYourTv.firstPanel;
    const configData2 = homeData[0].sky.choooseYourTv.secondPanel;
    const configData3 = homeData[0].sky.choooseYourTv.fasciaPanel;
    const configData4 = homeData[0].sky.choooseYourTv.media;

    let content = (
          <Layout uri={uri} dataHeader={dataHeader}>
            <section className="Configurator">
                {/* <NewHeader noConfig storeID={storeID} logo={logo}/> */}
                <div className="afterHeader">
                  <StepsContainer 
                    steps={steps} 
                    data1={configData1}
                    data2={configData2}
                    data3={configData3}
                    data4={configData4}
                    touched={touched}
                    setTouched={setTouched}
                    selectedFascia={selectedFascia}
                    setSelectedFascia={setSelectedFascia}
                    selectedColor={selectedColor}
                    setSelectedColor={setSelectedColor}
                    selectedSize={selectedSize}
                    setSelectedSize={setSelectedSize}
                    storeID={LSData.storeID}
                  />
                  <Barra 
                    selected1={selectedColor} 
                    selected2={selectedSize} 
                    selected3={selectedFascia}
                    steps={steps} 
                    setSteps={setSteps} 
                    data1={configData1} 
                    data2={configData2}
                    data3={configData3}
                    touched={touched}
                    setTouched={setTouched}
                    />
                </div>
            </section>
          </Layout>
    )

    return content
}

export default Configurator;


export const query = graphql`{
  wp {
    pages {
      nodes {
        title
        databaseId
        sky {
          choooseYourTv {
            fasciaPanel {
              mainText
              colorBlocks {
                colorCode
                colorTitle
                gallery {
                  mediaItemUrl
                }
                hexColor
                listOfImagesForColours {
                  fieldGroupName
                  s {
                    mediaItemUrl
                  }
                  l {
                    mediaItemUrl
                  }
                  m {
                    mediaItemUrl
                  }
                }
              }
            }
            firstPanel {
              colorBlocks {
                colorCode
                colorTitle
                gallery {
                  mediaItemUrl
                }
                hexColor
              }
              mainText
            }
            media {
              mainImagesWithSize {
                colorCluster {
                  sizecode
                  image {
                    mediaItemUrl
                  }
                }
              }
              mainText
              products {
                colorCode
                productPage {
                  ... on WP_Post {
                    databaseId
                  }
                }
                sizeCode
              }
              instructions {
                text
              }
              mainImages {
                image {
                  mediaItemUrl
                }
              }
            }
            secondPanel {
              mainText
              sizes {
                sizes {
                  inches
                  sizeCode
                  size
                  images {
                    image {
                      mediaItemUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;